$SteelBlue: #4682B4;
$DarkRed: #8B0000;
$DarkSlateGrey: #2F4F4F;
$charcoal: #42565a;
$offwhite: #f6f6f6;
$warning: #922a2a;
$teal: #26bfd4;
$dark-teal: #207b88;
$grey: #8a8a8a;
$peacefulBlue: #0875e1;
$navyBlue: #000080;