.navbar {
	@include navbar();

	.left-nav {
		@include nav-directions();
		padding-left: 50px;

		.app-title {
			a {
				text-decoration: none;
				color: black;
			}
		}

		.logo-img {
			img {
				width: 80px;
				height: 80px;
			}
		}
	}

	.right-nav {
		@include nav-directions();
		padding-right: 50px;
    gap: 30px;

		.btn {
			@include btn();
		}

		.login-icon {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.fa {
				@include FAbtn();
			}

 
		}

    .logOut-icon {
      display: flex;
			justify-content: flex-start;
			align-items: center;

      .fa {
				@include FAbtn();
			}

    }
	}
}
