.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}



.content {
	@include content();

	.info-wrapper {
		@include content-box();

		.mission-statement {
			@include content-organization();
		}
	}

}