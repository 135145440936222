.delete-container {
  @include content();

  .delete-box {
    @include delete-box();

    .title {
      color: $warning;
    }

    .btns {
      button {
        @include btn();
      }
    }
  }
}