@mixin navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  border-bottom: 0px none;
  box-sizing: border-box;
  background-color: #ffffff;
}

@mixin nav-directions {
  display: flex;
  justify-content: center;
}

@mixin content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 400px;
  padding: 75px 75px;
}

@mixin content-box {
  border-bottom: 0px none;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 25px 50px;
}

@mixin delete-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 0px none;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 25px 50px;
}

@mixin formbox {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@mixin form-inputs {
  input {
    font-family: 'Source Sans Pro', sans-serif;
    color: black;
    padding: 10px 1%;
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid black;
    background-color: transparent;
    transition: 0.5s all ease-in-out;
    font-size: 2em;
    margin-bottom: 21px;

    &:focus {
      outline: none;
      border-bottom: 1px solid transparent;
    }
  }
}

@mixin user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@mixin content-organization {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin footer {}

@mixin icon {
  a {
    color: $DarkRed;
    font-size: 2em;
  }
}

@mixin btn {
  cursor: pointer;
  height: 50px;
  margin-right: 20px;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid transparent;
  background-color: $peacefulBlue;
  color: whitesmoke;
  border-radius: 10px;
}

@mixin FAbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 20px;
  font-size: 1em;
  font-weight: 500;
  color: $charcoal;
  border: 1px solid transparent;
  border-radius: 10px;
}