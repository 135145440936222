.signup-container {
  @include content();
  
  
  .signup-form {
    @include content-box();
    
    form {
      
      @include formbox();

      @include form-inputs();

      button {
        @include btn();
      }

      h4 {
        color: $warning;
      }
    }
  }
}