.user-content {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 50px;
  padding: 25px;

  .blog-container {

    .blog-items {
      border: 1px solid black;
      @include content-box();
    }
  }

  .user-container {
    @include content-box();

    .name {
      @include user-details();

      h4 {
        color: $charcoal
      }
    }

    .EditMode {
      @include user-details();

      .fa {
        @include FAbtn();
      }
    }

    .delete {
      @include user-details();

      .fa {
        @include FAbtn();
      }
    }

    .create-post {
      @include user-details();
      
      button {
        @include btn();
      }
    }
  }

}