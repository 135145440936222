.update-user-container {
  @include content();
  padding: 100px 400px;

  .user-form {
    @include content-box();

    form {
      
      @include formbox();

      @include form-inputs();

      button {
        @include btn();
      }

      h4 {
        color: $warning;
      }
    }
  }
}